import { SupportButton } from '@components/support';
import * as React from 'react';

export const SupportContext = React.createContext<{
  openPanel: () => void
  togglePanel: () => void
  panelOpen: boolean
}>({
  panelOpen: false,
  openPanel: () => { throw 'Unimplemented' },
  togglePanel: () => { throw 'Unimplemented' }
})
export const SupportProvider = ({ children, screen }: { children: React.ReactNode, screen?: string }) => {
  const [panelOpen, setPanelOpen] = React.useState(false);
  const togglePanel = () => setPanelOpen(!panelOpen);
  const openPanel = React.useCallback(() => {
    setPanelOpen(true)
  }, [setPanelOpen])

  return (
    <SupportContext.Provider value={{
      openPanel: openPanel,
      togglePanel,
      panelOpen,
    }}>
      {children}
      <SupportButton screen={screen} />
    </SupportContext.Provider>
  );
};

export const useSupportContext = () => React.useContext(SupportContext);
