import { ImmigrationDocumentType } from '@codegen/schema';
import { OnboardingPageType } from '@pages/onboarding/types';
import React from 'react';
import { z } from 'zod';
import { ImmigrationDocumentForm } from './file-form';

export const studentVisaFormSchema = z.object({
  studentVisaStamps: z.string().min(1, 'Please upload your student visa')
});

export type StudentVisaFormType = z.infer<typeof studentVisaFormSchema>;
export const StudentVisaForm: React.FC<OnboardingPageType> = (props) => {

  return (
    <ImmigrationDocumentForm
      {...props}
      title={"Identification information"}
      subtitle={(
        <p>
          Have any U.S. student visa documents been issued? <b>If yes</b>, please upload copies.
        </p>
      )}
      cardTitle={"Upload student visa stamps: I-20 or DS-2019"}
      name={`visa-stamp-form`}
      fileType={ImmigrationDocumentType.StudentVisa}
    />
  )
}
