import { ImmigrationDocumentType } from '@codegen/schema';
import { OnboardingPageType } from '@pages/onboarding/types';
import React from 'react';
import { z } from 'zod';
import { ImmigrationDocumentForm } from './file-form';

export const visaFormSchema = z.object({
  visaStamps: z.string().min(1, 'Please upload your visa')
});

export type VisaFormType = z.infer<typeof visaFormSchema>;
export const VisaForm: React.FC<OnboardingPageType> = (props) => {
  return (
    <ImmigrationDocumentForm
      {...props}
      title={"Identification information"}
      subtitle={<p>Have any U.S. visa stamps ever been issued in your visa? <b>If yes</b>, please upload copies.</p>}
      cardTitle={"Upload visa stamps"}
      name={`visa-stamp-form`}
      fileType={ImmigrationDocumentType.VisaStamp}
    />
  )
}

export default VisaForm;
