import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "@components/button";
import Uploader from "@components/uploader/uploader";
import Spinner from "@components/spinner";
import { useAllCompanyDocsQuery } from "@codegen/index";
import { CompanyDocumentType } from "@codegen/schema";
import { useJwt } from "@utils/hooks";
import { api } from "@utils/api";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { Info } from "lucide-react";
import { HoverCard, HoverCardArrow, HoverCardContent, HoverCardTrigger } from "@components/hoverCard";
import { useEmployerOnboarding } from '../../employer-onboarding';
import { useStatsigClient } from "@statsig/react-bindings";
import { CompanyDocumentUploader } from "@pages/onboarding/shared/company-document-upload";

export const ForeignDocumentsForm = () => {
  const { onSubmit, company, onBack } = useEmployerOnboarding();
  const [businessLicenseUploaded, setBusinessLicenseUploaded] = useState(false);
  const token = useJwt();
  const { client } = useStatsigClient();
  const { data, loading, refetch } = useAllCompanyDocsQuery({
    variables: {
      companyId: company?.value,
    },
    skip: !company?.value,
  });

  const businessLicense = useMemo(() => {
    if (data == null || data.allCompanyDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of data.allCompanyDocuments.nodes) {
      if (doc?.fileByFileId?.id == null || doc?.fileByFileId?.deleted) {
        continue;
      }

      if (doc.type !== CompanyDocumentType.BusinessLicense) continue;
      res.push({
        id: doc.fileByFileId.id ?? "",
        name: doc.fileByFileId.name,
      });
    }

    setBusinessLicenseUploaded(res.length > 0);
    return res;
  }, [data, setBusinessLicenseUploaded]);
  const nameChangeDocument = useMemo(() => {
    if (data == null || data.allCompanyDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of data.allCompanyDocuments.nodes) {
      if (doc?.fileByFileId?.id == null || doc?.fileByFileId?.deleted) {
        continue;
      }

      if (doc.type !== CompanyDocumentType.NameChange) continue;
      res.push({
        id: doc.fileByFileId.id ?? "",
        name: doc.fileByFileId.name,
      });
    }

    return res;
  }, [data]);

  return (
    <EmployerOnboardingContainer
      title="Corporate Documents: The Building Blocks"
      subtitle=""
      cardTitle={<p>We want to prove that your business is “fully operational.” Please provide <b>ALL</b> that apply:</p>}
      progress={
        0
      }
      name="foreign_docs"
    >
      <div className="flex flex-col gap-y-4 w-[500px]">
        <div className="flex flex-col gap-y-4 w-[500px]">
          <div>
            <div>

              <ul className="list-disc list-inside mb-2">
                <li>Corporate tax return</li>
                <li>Business License</li>
                <li>Foreign Qualification&nbsp;
                  <HoverCard>
                    <HoverCardTrigger className="cursor-pointer inline-block align-text-bottom">
                      <Info size={16} className="text-accent" />
                    </HoverCardTrigger>
                    <HoverCardContent className="w-[450px]">
                      <div>
                        <p className="mb-2">If your company is headquartered in a state where you don't plan to operate, you may need to register as a foreign corporation in your operational state.</p>
                        <p className="font-bold mb-2">Example: Companies incorporated in Delaware but operating in California must do this. If you don’t have this, please find instructions here to apply for one. This usually takes 2-3 weeks.</p>
                        <p>Why? It keeps your business compliant with state laws.</p>
                        <p>You can checkout our guide <a onClick={() => {
                          client.logEvent('employer_onboarding', 'foreign_docs', {
                            action: 'click',
                            element: 'foreign_docs_guide_click_hover'
                          })
                          window.open("https://docs.google.com/document/d/1CIx49cfWzjzRu6nhC8ZZnUtcqzwCre1DD4JWgtybN8I/edit?usp=sharing", "_blank")
                        }} target="_blank" className="text-accent font-bold" href="https://docs.google.com/document/d/1CIx49cfWzjzRu6nhC8ZZnUtcqzwCre1DD4JWgtybN8I/edit?usp=sharing">here</a>.</p>
                      </div>
                      <HoverCardArrow className="fill-white" />
                    </HoverCardContent>
                  </HoverCard>
                  <p className="text-sm text-slate-500 pl-5"><b>Time-Sensitive Requirement:</b> For founders who have incorporated in Delaware, planning to hire employees or conduct business in another state (e.g., California or New York), this process should be initiated as soon as possible to prevent delays in starting operations.
                    This process can take 2-4 weeks to complete. Please start immediately to avoid delays in filing your visa application. You can checkout our guide <a onClick={() => {
                      client.logEvent('employer_onboarding', 'foreign_docs', {
                        action: 'click',
                        element: 'foreign_docs_guide_click'
                      })
                      window.open("https://docs.google.com/document/d/1CIx49cfWzjzRu6nhC8ZZnUtcqzwCre1DD4JWgtybN8I/edit?usp=sharing", "_blank")
                    }} target="_blank" className="text-accent font-bold" href="https://docs.google.com/document/d/1CIx49cfWzjzRu6nhC8ZZnUtcqzwCre1DD4JWgtybN8I/edit?usp=sharing">here</a>.
                  </p>
                </li>
              </ul>
            </div>
            {businessLicense == null && <Spinner />}
            {businessLicense != null && !loading && (
              <CompanyDocumentUploader
                key={`business-license`}
                fileType={CompanyDocumentType.BusinessLicense}
                data={businessLicense}
                loading={loading}
                refetch={refetch}
                company={company}
              />
            )}
          </div>
          <div>
            <div className="flex gap-2 items-center">
              <Button type="button" variant="link" className="text-md p-0">Corporate Name Change document (if applicable)</Button>
            </div>

            {nameChangeDocument == null && <Spinner />}
            {nameChangeDocument != null && !loading && (
              <CompanyDocumentUploader
                key={`name-change-document`}
                fileType={CompanyDocumentType.NameChange}
                data={nameChangeDocument}
                loading={loading}
                refetch={refetch}
                company={company}
              />
            )}
          </div>

        </div>
      </div>
      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={!businessLicenseUploaded}
          onClick={() => onSubmit({ data: [businessLicense?.map(d => d.id)], key: 'foreignDocsFileIds' })}
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};
