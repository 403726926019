import { useAllUserDocumentsByTypeQuery, useDeleteImmigrationDocumentMutation, useDeleteUserDocumentMutation, useExhibitFilesQuery, useImmigrationDocsByUserQuery, useUnlinkExhibitFiileMutation } from '@codegen/index';
import { CompanyDocumentType, ImmigrationDocumentType, IndividualDocumentType } from '@codegen/schema';
import { Button } from '@components/button';
import Spinner from '@components/spinner';
import Uploader from '@components/uploader/uploader';
import { CTAContainer } from '@pages/onboarding/shared/cta-container';
import { FieldsContainer } from '@pages/onboarding/shared/fields-container';
import { OnboardingContainer } from '@pages/onboarding/shared/onboarding-container';
import { OnboardingPageType } from '@pages/onboarding/types';
import { api } from '@utils/api';
import { useLogError } from '@utils/error';
import { useJwt } from '@utils/hooks';
import React, { useCallback } from 'react';
import { z } from 'zod';
import * as changeCase from 'change-case'
import { FileFootnote } from '@pages/onboarding/shared/footnotes';
import { CriticalRoleDistinguishedReputation, CriticalRoleInternalDocuments } from '@pages/onboarding/workflows/descriptions';
import { WorkflowAwardExhibit } from '@codegen/enums';
import { UploadFile, UploadFileStatus } from '@components/uploader/types';
import { ApolloError } from '@apollo/client';
import toast from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import { FormLoading } from './loading';
import { UploadStatus } from '@utils/files';

export const fileFormSchema = z.object({
  files: z.string().array().min(1, 'Please provide at least one file')
});

export type FileFormType = z.infer<typeof fileFormSchema>;

const DocumentUploadForm: React.FC<OnboardingPageType & { fileType: IndividualDocumentType | ImmigrationDocumentType | CompanyDocumentType, title: string, subtitle: React.ReactNode, cardTitle: React.ReactNode }> = ({
  contact, onBack, onSubmit, onSkip, fileType, title, subtitle, cardTitle, footnote, exhibit, data, refetch, uploadFile, deleteFile
}) => {
  const { required } = exhibit
  const token = useJwt()
  const logError = useLogError()

  const handleSubmit = async () => {
    if (data?.length && data.length > 0) {
      await refetch()
      const files = data?.map(n => n?.id)
      onSubmit({ files })
    } else if (!required) {
      onSkip?.()
    }
  }

  const [unlinkExhibitFile] = useUnlinkExhibitFiileMutation();
  const { data: exhibitFiles } = useExhibitFilesQuery({
    variables: {
      id: exhibit.exhibitId?.toString() ?? ''
    }
  })

  const handleDelete = async (file: UploadFileStatus) => {
    try {
      const exhibitFile = exhibitFiles?.exhibitById?.exhibitFilesByExhibitId?.nodes?.find(f => f?.fileByFileId?.id === file.id)
      // Unlink from exhibit if it exists
      if (exhibit.exhibitId && exhibitFile) {
        Sentry.addBreadcrumb({
          category: 'exhibit',
          message: `Unlinking exhibit file ${file.id} from exhibit ${exhibit.exhibitId}`
        })
        await unlinkExhibitFile({
          variables: {
            input: {
              clientMutationId: `unlink-exhibit-file-${exhibit.exhibitId}-${file.id}`,
              exhibitId: exhibit.exhibitId.toString(),
              fileId: file.id
            }
          }
        })
      }

      if (file.id) {
        await deleteFile(file.id)
      }
      await refetch()
    } catch (e) {
      logError(e, { contactId: contact.id, fileId: file.id })
      toast.error('An error occurred while deleting the file')
    }
  }

  const onFileUpload = React.useCallback(
    async (file: File) => {
      try {
        const data = await uploadFile(
          file,
          token,
          contact.id,
          fileType
        );
        if (!data.success || !data.id) throw new Error('An error occurred while uploading the file');
        const { id } = data
        const newFile = {
          id,
          name: file.name,
          success: data.success,
          fileId: id,
        }
        await refetch()
        return newFile
      } catch (exception) {
        logError(exception, {
          contactId: contact.id,
          fileType
        })
        return {
          success: false,
          name: file.name,
          type: file.type,
          link: ""
        }
      }
    },
    [contact.id, token, fileType]
  );

  return (
    <OnboardingContainer
      key={exhibit.id}
      title={title}
      subtitle={subtitle}
      cardTitle={cardTitle}
      progress={0}
      onSubmit={handleSubmit}
      exhibit={exhibit}
      name={`${changeCase.kebabCase(fileType)}-form`}
      footnote={footnote}
    >
      <FieldsContainer status={''}>
        <div>
          <Button variant="link" className="pl-0">
            {changeCase.sentenceCase(fileType)}
          </Button>
          {data == null && <Spinner />}
          {data != null && (
            <Uploader
              key={exhibit.id}
              multiple
              onFileUpload={onFileUpload}
              defaultFiles={data.map((x) => ({
                id: x.id ?? "",
                name: x.name ?? "",
              }))}
              allowDelete
              onDeleteFile={handleDelete}
            />
          )}
        </div>
      </FieldsContainer>
      <CTAContainer onBack={onBack} onSkip={required ? undefined : onSkip}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          type="submit"
          disabled={required && data?.length === 0}
        >
          Next
        </Button>
      </CTAContainer>
    </OnboardingContainer>
  );
}

export const OneIndividualDocumentForm: React.FC<OnboardingPageType & { fileType: IndividualDocumentType, title: string, subtitle: React.ReactNode, cardTitle: React.ReactNode }> = (props) => {
  const token = useJwt()
  const contact = props.contact
  const { data, loading, refetch } = useAllUserDocumentsByTypeQuery({
    variables: {
      documentType: props.fileType,
      userId: props.contact.id
    }
  })
  const logError = useLogError()

  const uploadFile = api.files.uploadIndividualDoc

  const [deleteUserDocument] = useDeleteUserDocumentMutation();
  const doDeleteUserDocument = React.useCallback(
    async (fileId: string) => {
      try {
        const { errors } = await deleteUserDocument({
          variables: {
            input: {
              clientMutationId: `delete-user-document-${contact.id}-${fileId}`,
              userId: contact.id,
              fileId,
            },
          },
        });
        if (errors != null) {
          throw new Error(errors[0].message)
        }
        return {
          success: true,
          error: undefined
        }
      } catch (e) {
        if (e instanceof Error) {
          logError(e, { contactId: contact.id, fileId })
        } else if (e instanceof ApolloError) {
          logError(e.graphQLErrors[0], { contactId: contact.id, fileId })
        } else {
          logError(e, { contactId: contact.id, fileId })
        }
        return {
          success: false,
          error: UploadStatus.ErrorBackend
        }
      }
    },
    [contact, deleteUserDocument]
  );

  if (loading) return <FormLoading />

  return (
    <DocumentUploadForm
      {...props}
      data={data?.allUserDocuments?.nodes?.map(node => node?.fileByFileId).filter(n => !!n) ?? []}
      loading={loading}
      refetch={refetch}
      uploadFile={uploadFile}
      deleteFile={doDeleteUserDocument}
    />
  );
}

export const ImmigrationDocumentForm: React.FC<OnboardingPageType & { fileType: ImmigrationDocumentType, title: string, subtitle: React.ReactNode, cardTitle: React.ReactNode }> = (props) => {
  const contact = props.contact
  const { data, loading, refetch } = useImmigrationDocsByUserQuery({
    variables: {
      ownerId: contact.id,
      type: props.fileType
    }
  })
  const logError = useLogError()

  const uploadFile = api.files.uploadImmigrationDoc

  const [deleteImmigrationDocument] = useDeleteImmigrationDocumentMutation();
  const doDeleteImmigrationDocument = React.useCallback(
    async (fileId: string) => {
      try {
        const { errors } = await deleteImmigrationDocument({
          variables: {
            input: {
              clientMutationId: `delete-user-immigration-doc-${props.fileType}-${contact.id}-${fileId}`,
              ownerId: contact.id,
              fileId,
            },
          },
        });
        if (errors != null) {
          throw new Error(errors[0].message)
        }
        return {
          success: true,
          error: undefined
        }
      } catch (e) {
        if (e instanceof Error) {
          logError(e, { contactId: contact.id, fileId })
        } else if (e instanceof ApolloError) {
          logError(e.graphQLErrors[0], { contactId: contact.id, fileId })
        } else {
          logError(e, { contactId: contact.id, fileId })
        }
        return {
          success: false,
          error: UploadStatus.ErrorBackend
        }
      }
    },
    [contact, deleteImmigrationDocument]
  );

  if (loading) return <FormLoading />

  return (
    <DocumentUploadForm
      {...props}
      data={data?.allImmigrationDocuments?.nodes?.map(node => node?.fileByFileId).filter(n => !!n) ?? []}
      loading={loading}
      refetch={refetch}
      uploadFile={uploadFile}
      deleteFile={doDeleteImmigrationDocument}
    />
  );
}

export const OfferLetterForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.OfferLetter}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>Please upload your job offer letter</p>}
    />
  )
}

export const EVLetterForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.EvLetter}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>Please upload your job employment verification letter</p>}
    />
  )
}

export const OrgChartForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.OrgChart}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>Please upload your organization chart</p>}
    />
  )
}

export const CalendarForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.Calendar}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>Please upload your calendar</p>}
    />
  )
}

export const MembershipLetterOrMailForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.ProofOfMembershipLetterOrEmail}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const MembershipProfilePagelForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.ProofOfMembershipProfilePage}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const JudgesProfileForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.Judges}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const JudgingInviteOrConfirmationForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.JudgingInviteOrConfirmation}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const JudgingSelectionCriteriaForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.EvidenceOfSelectionCriteria}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const JudgingScreenshotOfEventOrPrizesForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.ScreenshotOfEventOrPrizes}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const OCProductRoadmapForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.ProductRoadmap}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const OCTechnicalDocumentsForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.TechnicalDoc}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const OCOriginalCodeForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.OriginalCode}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}
export const OCUserCountOrRevenueForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.UserCountOrRevenue}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const PitchDeckForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.PitchDeck}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const VCEvidenceForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.VcEvidence}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const AwardCapTableForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.CapTable}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const AwardEvidenceOfSelectivityForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.EvidenceOfSelectivity}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const TaxReturnForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName, company } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.TaxReturn}
      subtitle={
        name
      }
      cardTitle={<p>Please upload your tax return for <em>{company?.companyName}</em> showing your total earnings in that role, if available</p>}
      title={moduleName}
    />
  )
}

export const PayStubsForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName, company } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.PayStubs}
      subtitle={
        name
      }
      cardTitle={<p>Please upload your final three consecutive pay stubs from your role at <em>{company?.companyName}</em></p>}
      title={moduleName}
    />
  )
}

export const PayBonusForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName, company } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.PayBonus}
      subtitle={
        name
      }
      cardTitle={<p>Please provide any pay statements showing a large bonus you may have received at <em>{company?.companyName}</em></p>}
      title={moduleName}
    />
  )
}

export const EquityStatementForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name } = exhibit
  const { name: moduleName, company } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.EquityStatement}
      subtitle={
        <>
          <p className="mb-3">If you received equity as part of your role, can you provide proof of your vested shares at the time of your employment at <b><em>{company?.companyName}</em></b> and proof of their value at that time?</p>
          <p>This could be a Carta Screenshot showing your granted equity shares and their valuation at <b><em>{company?.companyName}</em></b></p>
        </>
      }
      cardTitle={<p>Please provide a full page screenshot with URL link.</p>}
      title={moduleName}
    />
  )
}

export const EvidenceOfRegconitionForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.EvidenceOfRecognition}
      subtitle={
        name
      }
      cardTitle={<p>{name}</p>}
      title={moduleName}
    />
  )
}

export const PromotionDocumentForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.PromotionEvidence}
      subtitle={
        name
      }
      cardTitle={<p>Please provide any documentation showing promotions in this role</p>}
      title={moduleName}
    />
  )
}
export const PayRaiseEvidenceForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.PayRaiseEvidence}
      subtitle={
        name
      }
      cardTitle={<p>Please provide any documentation showing pay raise in this role</p>}
      title={moduleName}
    />
  )
}

export const CodeContributionDocumentsForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        <p>Code contributor page which clearly shows your name — the higher the code contributions, the better (the one with those cool graphs!)</p>
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.CodeContributorPage}
      footnote={FileFootnote}
    />
  )
}

export const CodeProjectOwnershipForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        <p>GitHub profile page and any pages showing your code project ownership — we want to see you’re an admin / and your pulls and commits</p>
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.CodeProjectOwnership}
      footnote={FileFootnote}
    />
  )
}

export const ConferencePresentationForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        <p>Conference presentations you've given at big-name events</p>
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.ConferencePresentation}
      footnote={FileFootnote}
    />
  )
}

export const InternalDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        CriticalRoleInternalDocuments
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.InternalDocs}
    />
  )
}

export const CustomerContractDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName, company } = module
  const subtitle = (
    <div>
      <p>Contracts you’ve signed on behalf of <b>{company?.companyName ?? 'the company'}</b></p>
    </div>
  )
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        description ? description : subtitle
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.CustomerContract}
    />
  )
}

export const AcademicContractProposalDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.AcademicContractProposal}
    />
  )
}

export const AcademicPapersDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.AcademicPaper}
    />
  )
}

export const FundingDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.FundingDocument}
    />
  )
}

export const DistinguishedReputationDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        CriticalRoleDistinguishedReputation
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.DistinguishedReputation}
      footnote={FileFootnote}
    />
  )
}

export const AwardEvidenceForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, award } = exhibit as WorkflowAwardExhibit
  const { name: moduleName } = module

  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={''}
      title={`${moduleName}${award ? ` - ${award.awardName}` : ''}`}
      fileType={IndividualDocumentType.AwardEvidence}
    />
  )
}

export const EvidenceOfCommercialAdoptionForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, award } = exhibit as WorkflowAwardExhibit
  const { name: moduleName } = module

  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={'Proof that your work has been commercially adopted or used in the real world'}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.EvidenceOfCommercialAdoption}
    />
  )
}

export const EvidenceOfSignificantUsageForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, award } = exhibit as WorkflowAwardExhibit
  const { name: moduleName } = module

  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={'Proof that a large number of people are using your technology (think in the thousands, and tens of thousands, if not more!)'}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.EvidenceOfSignificantUsage}
    />
  )
}

export const EvidenceIndustryAwardsForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, } = exhibit
  const { name: moduleName } = module

  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={'Proof that you received any industry awards or prizes.'}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.EvidenceOfIndustryAwards}
    />
  )
}
