import { ImmigrationDocumentType } from '@codegen/schema';
import { OnboardingPageType } from '@pages/onboarding/types';
import React from 'react';
import { z } from 'zod';
import {ImmigrationDocumentForm} from './file-form';
export const passportFormSchema = z.object({
  passports: z.string().min(1, 'Please upload your passport')
});

export type PassportFormType = z.infer<typeof passportFormSchema>;
export const PassportForm: React.FC<OnboardingPageType> = (props) => {
  return (
    <ImmigrationDocumentForm
      {...props}
      title={"Identification information"}
      subtitle={"If you have multiple passports, please upload the passport from your birth country as well as the passport you intend to use for travel."}
      cardTitle={"Upload passport"}
      name={`passport-form`}
      fileType={ImmigrationDocumentType.Passport}
    />
  )
}

export default PassportForm;
